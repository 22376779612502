var $ = require('jquery');
global.$ = global.jQuery = $;

var bootstrap = require('bootstrap/dist/js/bootstrap'),
    bs_slider = require('bootstrap-slider')
    // bs_select = require('bootstrap-select'),
    // bs_popover_extra_placement = require('./bootstrap.popover-extra-placements.min.js')
    ;
    // ConsumptionSlider = require('./ConsumptionSlider');

$.extend(window, {
    poco5: {
        jQuery: $
        // consumptionSlider: ConsumptionSlider
    },
    isPoco5Loaded: true
});